import Vue from 'vue';
import VueMeta from 'vue-meta';
import VueRouter from 'vue-router';

Vue.use(VueRouter);
Vue.use(VueMeta);
// importing components
// import HomePage from "@/components/pages/HomePage.vue";
// import AboutPage from "@/components/pages/AboutPage.vue";
// import ContactPage from "@/components/pages/ContactPage.vue";
// import CareersPage from "@/components/pages/CareersPage.vue";
// import TechnologyPage from "@/components/pages/TechnologyPage.vue";
// import BlogPage from "@/components/pages/BlogPage.vue";
// import BlogDetailsPage from "@/components/pages/BlogDetailsPage.vue";

const routes = [
	{
		path: '/',
		name: 'home',
		component: () => import('@/components/pages/HomePage.vue'),
	},
	{
		path: '/about-us',
		name: 'about-us',
		component: () => import('@/components/pages/AboutPage.vue'),
	},
	{
		path: '/contact/',
		name: 'contact',
		component: () => import('@/components/pages/ContactPage.vue'),
	},
	{
		path: '/technology/',
		name: 'technology',
		component: () => import('@/components/pages/TechnologyPage.vue'),
	},
	{
		path: '/remote-tech-support',
		name: 'remote-tech-support',
		component: () => import('@/components/pages/RemoteTechSupport.vue'),
	},
	{
		path: '/data-annotation',
		name: 'data-annotation',
		component: () => import('@/components/pages/DataAnnotation.vue'),
	},
	{
		path: '/enterprise-software-development',
		name: 'enterprise-software-development',
		component: () => import('@/components/pages/EnterpriseSoftwareDevelopment.vue'),
	},
	{
		path: '/mobile-application-development',
		name: 'mobile-application-development',
		component: () => import('@/components/pages/MobileApplicationDevelopment.vue'),
	},
	{
		path: '/cloud-application-development',
		name: 'cloud-application-development',
		component: () => import('@/components/pages/CloudApplicationDevelopment.vue'),
	},
	// {
	// 	path: '/blog',
	// 	name: 'blog',
	// 	component: BlogPage,
	// },
	// {
	// 	path: '/blog/:slug',
	// 	name: 'blog-details',
	// 	component: BlogDetailsPage,
	// },
	{
		path: '/careers/',
		name: 'careers',
		component: () => import('@/components/pages/CareersPage.vue'),
	},
];

const router = new VueRouter({
	routes: routes,
	mode: 'history',
});

export default router;
