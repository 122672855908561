<template>
  <div class="logo-container" data-cursor-target data-cursor-size="lg">
    <router-link :to="{ path: '/' }">
      <img
        src="@/assets/images/logo-white.svg"
        alt="AV DEVS Solutions Pvt. ltd"
        id="logo"
        v-if="type === 'white'"
      />
      <img
        src="@/assets/images/logo.svg"
        alt="AV DEVS Solutions Pvt. ltd"
        id="logo"
        v-else
      />
    </router-link>
  </div>
</template>

<script>
export default {
  name: "Logo",
  props: {
    type: {
      default: "default",
      type: String,
      validator: function(value) {
        return ["default", "white"].indexOf(value) !== -1;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.logo-container {
  width: clamp(140px, 26vw, 200px);
}
</style>
