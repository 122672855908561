<template>
	<div class="nav-item" data-cursor-target data-cursor-size="lg" ref="item">
		<router-link :to="{ path: url }" class="nav-link" v-if="url">
			<slot></slot>
		</router-link>
		<div class="nav-link" v-else>
			<slot></slot>
		</div>
		<div class="dropdown-wrapper" v-if="hasDropdown" ref="dropdown">
			<div class="dropdown">
				<slot name="dropdown"></slot>
			</div>
		</div>
	</div>
</template>

<script>
import { gsap } from "gsap";

export default {
	name: "NavItem",
	props: {
		url: {
			type: String,
		},
		hasDropdown: {
			type: Boolean,
		},
	},
	methods: {
		toggleDropdownHandler(e) {
			const dropdown = this.$refs.dropdown;
			const tl = gsap.timeline();
			if (e.type === "mouseleave") {
				tl.fromTo(dropdown, { autoAlpha: 1 }, { autoAlpha: 0, duration: 0.2, ease: "power3.out" });
				// tl.set(dropdown, { css: { display: "none" } });
			} else {
				// tl.set(dropdown, { css: { display: "block" } });
				tl.fromTo(dropdown, { autoAlpha: 0, y: 20 }, { autoAlpha: 1, y: 0, duration: 0.2, ease: "power3.out" });
			}
		},
	},
	mounted() {
		if (this.hasDropdown) {
			this.$refs.item.addEventListener("mouseenter", this.toggleDropdownHandler);
			this.$refs.item.addEventListener("mouseleave", this.toggleDropdownHandler);
		}
	},
	unmounted() {
		if (this.hasDropdown) {
			this.$refs.item.removeEventListener("mouseenter", null);
			this.$refs.item.removeEventListener("mouseleave", null);
		}
	},
};
</script>

<style lang="scss" scoped>
.nav-item {
	margin: 0 2rem;
	position: relative;

	.nav-link {
		text-transform: uppercase;
		color: $text-dark;
		font-size: 0.875rem;
		font-weight: 600;
		position: relative;

		&::before {
			content: "";
			width: 0;
			height: 1px;
			background-color: $secondary-color;
			position: absolute;
			bottom: -4px;
			left: 0;
			transition: 500ms cubic-bezier(0.3, 1, 0.7, 1);
		}

		&:hover,
		&:focus,
		&.router-link-exact-active {
			&::before {
				width: 60%;
			}
		}
	}

	.dropdown-wrapper {
		position: absolute;
		min-width: 260px;
		z-index: 1;
		// display: none;
		opacity: 0;
		visibility: hidden;

		.dropdown {
			background-color: #f5f5f5;
			width: 100%;
			height: 100%;

			&-list {
				list-style-type: none;
				padding: 6px;
				display: flex;
				flex-direction: column;
				gap: 6px;

				&-item {
					background-color: $white;
					line-height: 1;
					transition: 250ms ease;
					margin: 0;

					&--link {
						padding: 24px;
						display: block;
						width: 100%;
						height: 100%;
						text-transform: uppercase;
						color: $text-dark;
						font-size: 0.875rem;
						font-weight: 600;
						white-space: nowrap;
						line-height: 1;
						color: $text-dark;
					}

					&:hover {
						background-color: #e6e6e6;
					}
				}
			}
		}
	}
}
</style>
