<template>
	<nav>
		<NavItem url="/about-us">About Us</NavItem>
		<NavItem hasDropdown
			>Services
			<template v-slot:dropdown>
				<ul class="dropdown-list">
					<li class="dropdown-list-item">
						<router-link to="/remote-tech-support" class="dropdown-list-item--link">Remote Tech Support</router-link>
					</li>
					<li class="dropdown-list-item">
						<router-link to="/data-annotation" class="dropdown-list-item--link">Data Annotation</router-link>
					</li>
					<li class="dropdown-list-item">
						<router-link to="/cloud-application-development" class="dropdown-list-item--link">Cloud Application Development</router-link>
					</li>
					<li class="dropdown-list-item">
						<router-link to="/enterprise-software-development" class="dropdown-list-item--link"
							>Enterprise Software Development</router-link
						>
					</li>
					<li class="dropdown-list-item">
						<router-link to="/mobile-application-development" class="dropdown-list-item--link">Mobile Application Development</router-link>
					</li>
				</ul>
			</template>
		</NavItem>
		<NavItem url="/technology">Technology</NavItem>
		<!-- <NavItem url="/blog">Blog</NavItem> -->
		<NavItem url="/careers">Careers</NavItem>
		<router-link to="/contact">
			<CustomButton class="primary">
				Let's Talk
			</CustomButton>
		</router-link>
	</nav>
</template>

<script>
import NavItem from "@/components/layout/navigation/NavItem.vue";
import CustomButton from "@/components/utils/CustomButton.vue";

export default {
	name: "NavMenu",
	components: { NavItem, CustomButton },
};
</script>

<style lang="scss" scoped>
nav {
	display: flex;
	justify-content: space-around;
	align-items: center;

	&:first-child {
		margin-left: 0;
	}

	&:last-child {
		margin-right: 0;
	}
}

@media screen and (min-width: 993px) {
	nav {
		display: flex;
	}
}

@media screen and (max-width: 992px) {
	nav {
		display: none;
	}
}
</style>
