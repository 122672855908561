<template>
    <header>
        <div class="container">
            <div class="header-container">
                <Logo />
                <NavMenu />
                <Hamburger />
            </div>
        </div>
    </header>
</template>

<script>
import NavMenu from '@/components/layout/navigation/NavMenu.vue';
import Logo from '@/components/utils/Logo.vue';
import Hamburger from '@/components/utils/Hamburger.vue';

export default {
    name: 'Header',
    components: { NavMenu, Logo, Hamburger },
};
</script>

<style lang="scss" scoped>
header {
    background-color: $white;

    .header-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1.5rem 0;
    }
}
</style>
