<template>
    <div class="main-content">
        <div class="curtains">
            <div class="curtain curtain-back"></div>
            <div class="curtain curtain-front"></div>
        </div>
        <CustomCursor />
        <Header />
        <transition
            mode="out-in"
            v-on:before-enter="beforeEnter"
            v-on:enter="enter"
            v-on:before-leave="beforeLeave"
            v-on:leave="leave"
        >
            <router-view></router-view>
        </transition>
        <Footer />
        <MobileMenu />
    </div>
</template>

<script>
import Header from '@/components/layout/common/Header.vue';
import Footer from '@/components/layout/common/Footer.vue';
import MobileMenu from '@/components/layout/navigation/MobileMenu.vue';
import CustomCursor from '@/components/utils/CustomCursor.vue';
import { mapActions } from 'vuex';

export default {
    name: 'MainLayout',
    components: { Header, Footer, CustomCursor, MobileMenu },
    methods: {
        ...mapActions(['viewportWidth', 'fillTargets', 'checkCursorVisibility']),
        beforeLeave() {
            this.gsap.to('.curtains', { duration: 0, css: { display: 'block' } });
            this.gsap.to(['.curtain-back', '.curtain-front'], { y: '-100%' });
        },
        leave(el, done) {
            this.gsap.to(['.curtain-back', '.curtain-front'], {
                y: 0,
                duration: 0.5,
                ease: 'expo.inOut',
                stagger: 0.12,
            });

            this.gsap.to('.curtains', { delay: 1, duration: 0, css: { display: 'none' }, onComplete: done });
        },
        beforeEnter() {
            this.gsap.to('.curtains', { duration: 0, css: { display: 'block' } });
            this.gsap.to(['.curtain-back', '.curtain-front'], { y: 0 });
        },
        enter(el, done) {
            this.gsap.to(['.curtain-back', '.curtain-front'], {
                y: '100%',
                duration: 0.5,
                ease: 'expo.inOut',
                stagger: 0.12,
            });

            this.gsap.to(['.curtain-back', '.curtain-front'], {
                y: '-100%',
                duration: 0,
                delay: 1.2,
            });

            this.gsap.to('.curtains', { delay: 2, duration: 0, css: { display: 'none' }, onComplete: done });
            window.scrollTo(0, 0);
        },
    },
    mounted() {
        this.viewportWidth();
        this.fillTargets();
        this.checkCursorVisibility();

        window.addEventListener('resize', () => {
            this.viewportWidth();
            this.checkCursorVisibility();
        });
    },
};
</script>

<style lang="scss" scoped>
.curtains {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 9999;
    pointer-events: none;
    overflow: hidden;

    .curtain {
        background-color: $secondary-color;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        transform: translate3d(0, -100%, 0);

        &.curtain-back {
            background-color: $primary-color;
        }
    }
}
</style>

