<template>
  <div
    :class="baseClass"
    ref="baseCursor"
    :style="{ display: getCursorVisibility ? 'block' : 'none' }"
  >
    <div class="outer-cursor">
      <div class="inner-cursor"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "CustomCursor",
  data() {
    return {
      pos: {
        x: 0,
        y: 0,
      },
      vel: {
        x: 0,
        y: 0,
      },
      speed: 0.76,
      skew: 2.4,
    };
  },
  computed: {
    ...mapGetters(["getCursorSize", "getCursorVisibility"]),
    baseClass() {
      return `base-cursor size-${this.getCursorSize}`;
    },
  },
  mounted() {
    window.addEventListener("mousemove", (e) => {
      this.gsap.to(this.pos, {
        x: e.clientX,
        y: e.clientY,
        overwrite: true,
        ease: "expo.out",
        duration: this.speed,
        onUpdate: () => {
          return (this.vel = {
            x: e.clientX - this.pos.x,
            y: e.clientY - this.pos.y,
          });
        },
      });
    });

    document.addEventListener("mouseenter", () => {
      this.isVisible = true;
    });
    document.addEventListener("mouseleave", () => {
      this.isVisible = false;
    });
    this.run();
  },
  methods: {
    getScale(a, b) {
      return Math.abs(
        Math.min(Math.sqrt(Math.pow(a, 2) + Math.pow(b, 2)) / 1e3, 0.075)
      );
    },
    getAngle(a, b) {
      return (180 * Math.atan2(b, a)) / Math.PI;
    },
    run() {
      window.requestAnimationFrame(this.run);
      if (0 === this.vel.y || 0 === this.vel.x) {
        return false;
      }

      let angle = this.getAngle(this.vel.x, this.vel.y);
      let scaleFactor = this.getScale(this.vel.x, this.vel.y) * this.skew;

      this.gsap.set(this.$refs.baseCursor, {
        x: this.pos.x,
        y: this.pos.y,
        rotation: angle,
        scaleX: 1 + scaleFactor,
        scaleY: 1 - scaleFactor,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.base-cursor {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  width: 0;
  height: 0;
  pointer-events: none;
  backface-visibility: hidden;
  //   mix-blend-mode: difference;
  transition: opacity 750ms;

  .outer-cursor {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .inner-cursor {
    border: 2px solid $text-light;
    /* background-color: $white; */
    min-width: 5rem;
    min-height: 5rem;
    width: 6vw;
    height: 6vw;
    border-radius: 50%;
    transition: transform 750ms cubic-bezier(0.14, 1, 0.86, 1);
    transform-origin: center;
  }

  &.size-default {
    .outer-cursor {
      .inner-cursor {
        transform: scale(0.4);
      }
    }
  }

  &.size-enlarge {
    .outer-cursor {
      .inner-cursor {
        transform: scale(0.75);
      }
    }
  }
}
</style>
