const state = {
    cursorSize: 'default',
    cursorVisibility: false,
    cursorTargets: [],
};

const getters = {
    getCursorSize: (state) => state.cursorSize,
    getCursorVisibility: (state) => state.cursorVisibility,
    getCursorTargets: (state) => state.cursorTargets,
};

const actions = {
    fillTargets: ({ commit }) => {
        let targets = document.querySelectorAll('[data-cursor-target]');
        targets.forEach((target) => {
            target.addEventListener('mouseenter', () => {
                commit('setCursorSize', 'enlarge');
            });
            target.addEventListener('mouseleave', () => {
                commit('setCursorSize', 'default');
            });
        });
        commit('setCursorTargets', targets);
    },
    checkCursorVisibility: ({ commit }) => {
        if (window.innerWidth > 992) {
            commit('setCursorVisibility', true);
        } else {
            commit('setCursorVisibility', false);
        }
    },
};

const mutations = {
    setCursorTargets: (state, payload) => (state.cursorTargets = payload),
    setCursorSize: (state, payload) => (state.cursorSize = payload),
    setCursorVisibility: (state, payload) => (state.cursorVisibility = payload),
};

export default {
    state,
    getters,
    actions,
    mutations,
};
