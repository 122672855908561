import Vue from "vue";
import Vuex from "vuex";

// Modules
import menu from "./modules/menu";
import cursor from "./modules/cursor";
// import blog from "./modules/blog";
import siteData from "@/lang/sitedata.json";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    menu,
    cursor,
    // blog
  },
  state: {
    siteData: siteData,
  },
});
