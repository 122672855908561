const state = {
  isMenuOpened: false,
  isMobile: true,
};

const getters = {
  getMenuOpened: (state) => state.isMenuOpened,
  getMobile: (state) => state.isMobile,
};

const actions = {
  toggleMenu({ commit }, status) {
    commit("setMenuOpened", status);
  },
  viewportWidth({ commit }) {
    if (window.innerWidth > 992) {
      commit("setMobile", false);
    } else {
      commit("setMobile", true);
    }
  },
};

const mutations = {
  setMenuOpened: (state, payload) => (state.isMenuOpened = payload),
  setMobile: (state, payload) => (state.isMobile = payload),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
