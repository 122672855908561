<template>
	<footer>
		<div class="container">
			<div class="footer-container">
				<div class="footer-info">
					<div class="footer-logo">
						<Logo type="white" />
					</div>
					<div class="footer-info_contact">
						<h3 class="title">GET IN TOUCH</h3>
						<div class="details">
							<div class="details-address">
								<p class="address">404 Nilamber Primero, near<br />Nilamber Circle, Vadodara, <br />Gujarat, India - 390021</p>
							</div>
							<div class="details-contact">
								<a href="mailto:info@avdevs.com" class="email" data-cursor-target data-cursor-size="lg">
									info@avdevs.com
								</a>
							</div>
						</div>
					</div>
					<CustomButton class="tertiary">
						<router-link to="/contact">Let's Talk</router-link>
					</CustomButton>
				</div>
				<div class="footer-credits">
					<div class="footer-credits_copyright">
						<p>AV DEVS Solutions © 2022. All Rights reserved.</p>
					</div>
					<div class="footer-credits_socials">
						<div class="social" data-cursor-target data-cursor-size="lg">
							<a href="https://www.linkedin.com/company/avdevs/mycompany/" target="_blank" title="AV DEVS Solutions | LinkedIn">
								<img src="@/assets/images/icon-linkedin.svg" alt="AV DEVS Solutions | LinkedIn" />
							</a>
						</div>
						<div class="social" data-cursor-target data-cursor-size="lg">
							<a href="https://www.facebook.com/AVDEVSSolutions/" target="_blank" title="AV DEVS Solutions | Facebook">
								<img src="@/assets/images/icon-facebook.svg" alt="AV DEVS Solutions | Facebook" />
							</a>
						</div>
						<div class="social" data-cursor-target data-cursor-size="lg">
							<a href="https://github.com/avdevs" target="_blank" title="AV DEVS Solutions | Github">
								<img src="@/assets/images/icon-github.svg" alt="AV DEVS Solutions | Github" />
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
import Logo from "@/components/utils/Logo.vue";
import CustomButton from "@/components/utils/CustomButton.vue";

export default {
	name: "Footer",
	components: { Logo, CustomButton },
};
</script>

<style lang="scss" scoped>
footer {
	background-color: $blue;
	position: relative;

	.footer-container {
		.footer-info {
			padding: 3rem 0 6rem;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.footer-info_contact {
				.title {
					font-size: 1.25rem;
					color: $white;
					margin-bottom: 1rem;
				}

				.details {
					display: flex;
					justify-content: space-between;

					.details-address {
						display: flex;
						align-items: flex-start;
						margin-right: 3.8rem;

						img {
							width: 18px;
							height: 20px;
							margin-right: 0.8rem;
						}
						.address {
							width: 24ch;
							font-size: 1rem;
							line-height: 1.35rem;
							color: $text-light;
							font-weight: 600;
						}
					}

					.details-contact {
						a {
							display: flex;
							align-items: center;
							color: $white;
							font-size: 1rem;
							line-height: 1.35rem;
							color: $text-light;
							font-weight: 600;
							position: relative;

							&::before {
								content: "";
								width: 0;
								height: 1px;
								background-color: $text-light;
								position: absolute;
								bottom: -3px;
								right: 0;
								transition: 500ms cubic-bezier(0.3, 1, 0.7, 1);
							}

							&:hover,
							&:focus {
								&::before {
									width: 60%;
								}
							}

							&.phone {
								margin-bottom: 1rem;
							}

							img {
								width: 20px;
								margin-right: 0.8rem;
							}
						}
					}
				}
			}
		}

		.footer-credits {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			padding: 0 4.5vw;
			display: flex;
			justify-content: space-between;
			align-items: center;
			background: rgba(255, 255, 255, 0.1);

			&_copyright {
				font-size: 0.9rem;
				color: $text-light;
				font-weight: 600;
			}

			&_socials {
				.social {
					display: inline-block;
					width: 18px;
					height: 18px;
					margin: 1rem;
					position: relative;

					&:first-of-type {
						margin-left: 0;
					}

					&:last-of-type {
						margin-right: 0;
					}

					&::before {
						content: "";
						width: 0;
						height: 1px;
						background-color: $text-light;
						position: absolute;
						bottom: -6px;
						left: 0;
						transition: 500ms cubic-bezier(0.3, 1, 0.7, 1);
					}

					&:hover,
					&:focus {
						&::before {
							width: 100%;
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 1200px) {
	footer {
		.footer-container {
			.footer-info {
				.footer-info_contact {
					.title {
						margin-bottom: 1rem;
					}
					.details {
						.details-address {
							margin-right: 2.4rem;
							img {
								width: 16px;
								height: 19px;
							}
							.address {
								font-size: 0.9rem;
							}
						}
						.details-contact {
							a {
								font-size: 0.9rem;
								img {
									width: 18px;
								}
							}
						}
					}
				}
			}
			.footer-credits {
				&_copyright {
					font-size: 0.875rem;
				}
			}
		}
	}
}

@media screen and (max-width: 992px) {
	footer {
		.footer-container {
			.footer-info {
				flex-direction: column;
				justify-content: flex-start;
				align-items: center;

				.footer-logo {
					align-self: auto;
					margin-bottom: 3rem;
				}

				.footer-info_contact {
					margin-bottom: 3rem;

					.title {
						font-size: 1rem;
						margin-bottom: 1.5rem;
						text-align: center;
					}
					.details {
						.details-address {
							margin-right: 4rem;
							img {
								width: 16px;
								height: 19px;
							}
						}
					}
				}
			}

			.footer-credits {
				&_copyright {
					font-size: 0.75rem;
				}
			}
		}
	}
}

@media screen and (max-width: 576px) {
	footer {
		.footer-container {
			.footer-info {
				flex-direction: column;
				justify-content: flex-start;
				align-items: center;
				padding-bottom: 9rem;

				.footer-logo {
					align-self: auto;
					margin-bottom: 3rem;
				}

				.footer-info_contact {
					margin-bottom: 3rem;

					.title {
						font-size: 1rem;
						margin-bottom: 1.5rem;
						text-align: center;
					}
					.details {
						flex-direction: column;
						justify-content: flex-start;
						align-items: center;

						.details-address {
							margin-right: 0;
							margin-bottom: 1rem;
						}
					}
				}
			}
			.footer-credits {
				flex-direction: column-reverse;
				&_copyright {
					font-size: 0.75rem;
					margin: 1rem 0;
					text-align: center;
				}
			}
		}
	}
}
</style>
