<template>
  <button
    :class="getMenuOpened ? 'hamburger open' : 'hamburger'"
    v-on:click="clickHandler"
    ref="hamburger"
    :disabled="isDisabled"
  >
    <!-- <div class="lines">
            <div class="line line-1"></div>
            <div class="line line-2"></div>
        </div> -->
    <svg
      width="40"
      height="40"
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
    >
      <!-- <circle cx="50" cy="50" r="30" /> -->
      <path class="line--1" d="M0 40h62c13 0 6 28-4 18L35 35" />
      <path class="line--2" d="M0 50h70" />
      <path class="line--3" d="M0 60h62c13 0 6-28-4-18L35 65" />
    </svg>
  </button>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Hamburger",
  data() {
    return {
      isDisabled: false,
    };
  },
  computed: mapGetters(["getMenuOpened"]),
  methods: {
    ...mapActions(["toggleMenu"]),
    clickHandler() {
      this.setDisabled();
      if (this.getMenuOpened) {
        this.toggleMenu(false);
      } else {
        this.toggleMenu(true);
      }
    },
    setDisabled() {
      this.isDisabled = !this.isDisabled;
      setTimeout(() => {
        this.isDisabled = false;
      }, 1200);
    },
  },
};
</script>

<style lang="scss" scoped>
.hamburger {
  position: fixed;
  right: 4vw;
  width: 60px;
  height: 60px;
  cursor: pointer;
  z-index: 9999;
  border: 1px solid transparent;
  border-radius: 50%;
  overflow: hidden;
  background: none;
  transition: 1s cubic-bezier(0.4, 0, 0.24, 0.96);

  path {
    fill: none;
    stroke: $secondary-color;
    stroke-width: 3;
    stroke-linecap: round;
    stroke-linejoin: round;
    --length: 24;
    --offset: -38;
    stroke-dasharray: var(--length) var(--total-length);
    stroke-dashoffset: var(--offset);
    transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  svg {
    transform: scale(2.25);
  }

  .line--1,
  .line--3 {
    --total-length: 126.64183044433594;
  }
  .line--2 {
    --total-length: 70;
  }

  &.open {
    svg {
      .line--1,
      .line--3 {
        --length: 22.627416998;
      }
      .line--2 {
        --length: 0;
      }
    }

    svg {
      .line--1,
      .line--3 {
        --offset: -94.1149185097;
      }
      .line--2 {
        --offset: -50;
      }
    }
    path {
      stroke: $text-light;
    }
  }
}

@media screen and (min-width: 993px) {
  .hamburger {
    display: none;
  }
}

@media screen and (max-width: 992px) {
  .hamburger {
    display: block;
  }
}
</style>
