<template>
    <button class="custom-btn" v-on:click="$emit('clicked')" data-cursor-target data-cursor-size="lg">
        <slot></slot>
    </button>
</template>

<script>
export default {
    name: 'CustomButton',
};
</script>

<style lang="scss" scoped>
.custom-btn {
    border: 2px solid $primary-color;
    background: transparent;
    color: $white;
    padding: 0.875rem 1.5rem;
    font-size: 0.9rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    cursor: pointer;
    position: relative;
    z-index: 2;
    transition: 750ms cubic-bezier(0.3, 1, 0.7, 1);

    a {
        color: $white;
        transition: 750ms cubic-bezier(0.3, 1, 0.7, 1);
    }

    &::before {
        content: '';
        background-color: $primary-color;
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        z-index: -1;
        transition: 750ms cubic-bezier(0.3, 1, 0.7, 1);
    }

    &:hover {
        color: $primary-color;

        a {
            color: $primary-color;
        }

        &::before {
            right: 0;
            left: auto;
            width: 0;
        }
    }

    &.primary {
        border: 2px solid $primary-color;
        color: $white;
        a {
            color: $white;
        }

        &::before {
            background-color: $primary-color;
        }

        &:hover {
            color: $primary-color;

            a {
                color: $primary-color;
            }
        }
    }

    &.secondary {
        border: 2px solid $white;
        color: $secondary-color;

        a {
            color: $secondary-color;
        }

        &::before {
            background-color: $white;
        }

        &:hover {
            color: $white;

            a {
                color: $white;
            }
        }
    }

    &.tertiary {
        border: 2px solid $white;
        color: $primary-color;

        a {
            color: $primary-color;
        }

        &::before {
            background-color: $white;
        }

        &:hover {
            color: $white;

            a {
                color: $white;
            }
        }
    }
}
</style>
