import Vue from "vue";
import App from "./App.vue";
import router from "./router/index";
import gsap from "gsap";
import store from "./store";

Vue.config.productionTip = false;

Vue.mixin({
  created: function() {
    this.gsap = gsap;
  },
});

new Vue({
  render: (h) => h(App),
  store: store,
  router: router,
}).$mount("#app");
