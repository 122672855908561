<template>
	<MainLayout />
</template>

<script>
import MainLayout from '@/components/layout/MainLayout.vue';

export default {
	name: 'App',
	components: { MainLayout },
};
</script>

<style lang="scss">
// Keep this comment for pre-loading sass files. Its acting wierd otherwise.
</style>
